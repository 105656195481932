import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { ReleaseBox } from "../components/ReleaseBox"
import { graphql } from "gatsby"

const MusicPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark

  return (
    <>
      <Helmet>
        <title>Tüdel Beats - Releases</title>
      </Helmet>

      <Layout>
        <section className="section">
          <div className="container">
            <h1 className="title">Releases</h1>
            {edges.map(edge => {
              const { excerpt, frontmatter, id, fields } = edge.node
              return (
                <ReleaseBox
                  key={id}
                  id={frontmatter.id}
                  title={frontmatter.title}
                  slug={fields.slug}
                  excerpt={excerpt}
                />
              )
            })}
          </div>
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            id
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default MusicPage
