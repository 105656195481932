import React from "react"
import { Link } from "gatsby"
import { Cover } from "./Cover"

const ReleaseBox = ({ id, title, slug, excerpt }) => {
  return (
    <div className="box">
      <article className="media">
        <div className="media-left">
          <Link to={slug}>
            <figure className="image is-128x128">
              <Cover id={(title, id)} />
            </figure>
          </Link>
        </div>
        <div className="media-content">
          <div className="content">
            <Link to={slug}>
              <h1 className="title">{title}</h1>
            </Link>
            <p>
              {excerpt} <Link to={slug}>Read more &#10132;</Link>
            </p>
          </div>
        </div>
      </article>
    </div>
  )
}

export { ReleaseBox }
